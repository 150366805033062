export const FCL_REDIRECT_URL_PARAM_NAME = 'fcl_redirect_url'
export const FCL_RESPONSE_PARAM_NAME = 'fclResponseJson'

export const CORE_STRATEGIES = {
  "HTTP/RPC": "HTTP/RPC",
  "HTTP/POST": "HTTP/POST",
  "IFRAME/RPC": "IFRAME/RPC",
  "POP/RPC": "POP/RPC",
  "TAB/RPC": "TAB/RPC",
  "EXT/RPC": "EXT/RPC",
  "DEEPLINK/RPC": "DEEPLINK/RPC"
}
